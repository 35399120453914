import React, { useCallback, useEffect, useState } from 'react';

import Router from 'next/router';

import { Form, Input, Button, message } from 'antd';

import analytics from '@repo/analytics';
import { getMe, hasCmsAccess, login } from 'apiClient';
import { Layout } from 'features/ui/Layout';
import { useProofOfWork } from 'hooks/useProofOfWork';
import { Account, UserWithToken } from 'types/user';
import {
  getCmsAuthCookie,
  removeCmsAuthCookie,
  setCmsAuthCookie,
} from 'utils/cookies';

type Props = {
  account?: Account;
};

const LoginPage: React.FC<Props> = () => {
  const { challenge, fetchChallenge } = useProofOfWork();
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    const token = getCmsAuthCookie();

    /**
     * If a token exists, validate it.
     * - If valid, redirect user to the homepage.
     * - Otherwise, remove invalid token from cookies and allow user to proceed.
     */
    if (token) {
      setIsEnabled(false);
      getMe(token)
        .then((response) => {
          if (!response.user) {
            removeCmsAuthCookie();
            return;
          }

          Router.push('/');
        })
        .catch(() => {
          removeCmsAuthCookie();
        })
        .finally(() => {
          setIsEnabled(true);
        });
    }

    if (challenge.isEnabled && !challenge.nonce) {
      fetchChallenge();
    }
  }, [challenge, fetchChallenge]);

  const onFinish = useCallback(
    async ({ email, password }) => {
      setIsEnabled(false);

      const loginResponse = await login(email, password, challenge);
      if ('error' in loginResponse) {
        message.error('Invalid credentials');
        setIsEnabled(true);
        if (challenge.isEnabled) {
          fetchChallenge();
        }
        return;
      }

      const auth = loginResponse as UserWithToken;
      // If user has no access, we pretty much don't want them retrying, so there's no need of a new PoW challenge
      if (!hasCmsAccess(auth)) {
        message.error('Invalid credentials');
        setIsEnabled(true);
        return;
      }
      const accessToken: string = auth.accessToken;
      setCmsAuthCookie(accessToken);

      // Identify user in analytics
      analytics.identify(auth.id, {
        email: auth.email,
        name: auth.name,
        id: auth.id,
      });

      Router.push('/');
    },
    [challenge, fetchChallenge]
  );

  return (
    <Layout>
      <main>
        <Form
          disabled={!isEnabled}
          initialValues={{ remember: true }}
          labelCol={{ span: 8 }}
          name="basic"
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button htmlType="submit" type="primary">
              {isEnabled ? 'Login' : 'Authenticating...'}
            </Button>
          </Form.Item>
        </Form>
      </main>
      <style jsx>{`
        main {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 360px;
        }
        main form {
          width: 480px;
        }
      `}</style>
    </Layout>
  );
};

export default LoginPage;
